import Builder, {
	BuilderContext,
	BuilderContextI,
	Widget,
	WidgetItem,
	WidgetRenderer,
	BuilderElement,
	BuilderProps,
	BuilderSettings,
} from './builder.js';
import Dropper from './dropper.js';
import BoxInput from './form-items/box-input.js';
import BuilderColorPicker from './form-items/builder-color-picker.js';
import CornerBoxInput from './form-items/corner-box-input.js';
import GridEditor from './form-items/grid-editor.js';
import RichTextEditor from './form-items/rich-text-editor.js';
import useElements from './hooks/useElements.js';
import InactiveBackground from './inactive-background.js';
import { translations } from './translations.js';
import BuilderBody from './widgets/builder-body-widget.js';
import builderWidgets from './widgets/builderWidgets.js';

export default Builder;

const Widgets = {
	builderWidgets,
	BuilderBody,
};

const Hooks = {
	useElements,
};

const Forms = {
	BoxInput,
	ColorPicker: BuilderColorPicker,
	CornerBoxInput,
	GridEditor,
	RichTextEditor,
};

const Components = {
	Dropper,
	InactiveBackground,
};

export {
	BuilderContext,
	BuilderContextI,
	Widget,
	WidgetItem,
	WidgetRenderer,
	BuilderElement,
	BuilderProps,
	BuilderSettings,
	translations,
	Components,
	Widgets,
	Hooks,
	Forms,
};
