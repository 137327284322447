import { type RenderHtml, type WompoComponent, type WompoProps, registeredComponents } from 'wompo';
import { HtmlResultProps } from './html-result.js';

export default function getHtml(Component: WompoComponent, props: HtmlResultProps) {
	const toRender = Component(props);
	const res = handleHtmlTemplate(toRender);
	const htmlString = res
		.replace(/\s[a-zA-Z]*=_\$RM\$_/g, '')
		.replace(/[\n\t]/g, ' ')
		.replace(/\s+/g, ' ');
	return htmlString;
}

const handleValue = (value: any, part: string, pendingTags: WompoComponent[]) => {
	let string = '';
	let shouldIncrement = true;
	if (value?._$wompoHtml) {
		string += handleHtmlTemplate(value);
	} else if (part.endsWith('<') && value) {
		if (value.componentName) {
			string += value.componentName;
			pendingTags.push(value);
		} else if (typeof value === 'string') {
			string += value;
		}
	} else if (part.endsWith('</') && pendingTags.length) {
		string += pendingTags.pop().componentName;
		shouldIncrement = false;
	} else if (Array.isArray(value)) {
		for (const val of value) {
			const [elaborated] = handleValue(val, part, pendingTags);
			string += elaborated;
		}
	} else if (typeof value !== 'string' && part.endsWith('style=') && value) {
		string += `"${handleStyleObj(value)}"`;
	} else {
		if (value !== undefined && value !== null && value !== false) {
			if (part.endsWith('=')) string += `"${value}"`;
			else string += value;
		} else if (part.endsWith('=')) {
			string += '_$RM$_';
		}
	}
	return [string, shouldIncrement];
};

const handleHtmlTemplate = (renderHtml: RenderHtml) => {
	let string = '';
	const pendingClosingTags = [];
	let valueIndex = 0;
	for (let i = 0; i < renderHtml.parts.length; i++) {
		const part = renderHtml.parts[i];
		const value = renderHtml.values[valueIndex];
		string += part;
		const [toAdd, shouldIncrement] = handleValue(value, part, pendingClosingTags);
		string += toAdd;
		if (shouldIncrement) valueIndex++;
	}
	return string;
};

export const handleStyleObj = (styleObj: any) => {
	let styleString = '';
	const styles = Object.keys(styleObj ?? {});
	for (const key of styles) {
		let styleValue = styleObj[key];
		let styleKey = key.replace(/[A-Z]/g, (letter) => '-' + letter.toLowerCase());
		if (typeof styleValue === 'number') styleValue = `${styleValue}px`;
		if (
			typeof styleValue === 'string' &&
			(styleValue.startsWith('http') || styleValue.startsWith('/'))
		)
			styleValue = `url(${styleValue})`;
		if (
			styleValue !== undefined &&
			styleValue !== null &&
			styleValue !== false &&
			styleValue !== ''
		)
			styleString += `${styleKey}:${styleValue};`;
	}
	return styleString;
};
