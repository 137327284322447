import IconButton from 'emcomp/library/components/icon-button/icon-button.js';
import MenuItem, { MenuItemElement } from 'emcomp/library/components/menu/menu-item.js';
import Menu, { MenuElement } from 'emcomp/library/components/menu/menu.js';
import TextInput, { TextInputElement } from 'emcomp/library/components/text-input/text-input.js';
import { defineWompo, html, useExposed, useRef, useState, WompoProps } from 'wompo';

interface BoxInputProps extends WompoProps {
	label?: string;
	name?: string;
	disabled?: boolean;
	readonly?: boolean;
	value?: string;
	canChangeUnit?: boolean;
}

const pxIcon = html`<span style="color: var(--em-color-dark-light); font-size: 16px;">px</span>`;

const linkIcon = html`
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		class="bi bi-link"
		viewBox="0 0 16 16"
	>
		<path
			d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"
		/>
		<path
			d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"
		/>
	</svg>
`;

export default function BoxInput({
	value: initialValue,
	label,
	name,
	disabled,
	readonly,
	canChangeUnit,
	styles: s,
}: BoxInputProps) {
	const [value, setValue] = useState(initialValue ?? '');
	const [linked, setLinked] = useState(true);
	const [units, setUnits] = useState(['px', 'px', 'px', 'px']);

	const menuRef = useRef<MenuElement>();
	const unitPositionRef = useRef<'top' | 'bottom' | 'left' | 'right'>(null);

	const topInputRef = useRef<TextInputElement>();
	const rightInputRef = useRef<TextInputElement>();
	const bottomInputRef = useRef<TextInputElement>();
	const leftInputRef = useRef<TextInputElement>();

	const onUnitChanged = (ev: InputEvent) => {
		const menuItem = ev.currentTarget as MenuItemElement;
		const newUnit = menuItem.props.value;
		const position = unitPositionRef.current;
		let newUnits = [];
		if (linked) {
			newUnits = [newUnit, newUnit, newUnit, newUnit];
		} else if (linked === null) {
			if (position === 'top' || position === 'bottom')
				newUnits = [newUnit, units[1], newUnit, units[3]];
			if (position === 'left' || position === 'right')
				newUnits = [units[0], newUnit, units[2], newUnit];
		} else {
			if (position === 'top') newUnits = [newUnit, units[1], units[2], units[3]];
			if (position === 'bottom') newUnits = [units[0], units[1], newUnit, units[3]];
			if (position === 'left') newUnits = [units[0], units[1], units[2], newUnit];
			if (position === 'right') newUnits = [units[0], newUnit, units[2], units[3]];
		}
		setUnits(newUnits);
		menuRef.current.close();
		unitPositionRef.current = null;
		let top = newUnits[0] === 'auto' ? '' : topInputRef.current.value || '0';
		let right = newUnits[1] === 'auto' ? '' : rightInputRef.current.value || '0';
		let bottom = newUnits[2] === 'auto' ? '' : bottomInputRef.current.value || '0';
		let left = newUnits[3] === 'auto' ? '' : leftInputRef.current.value || '0';
		setValue(
			`${top}${newUnits[0]} ${right}${newUnits[1]} ${bottom}${newUnits[2]} ${left}${newUnits[3]}`
		);
		Promise.resolve().then(() => {
			this.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
			this.dispatchEvent(new Event('change', { bubbles: true, composed: true }));
		});
	};

	const openMenu = (ev: MouseEvent, position: 'top' | 'bottom' | 'left' | 'right') => {
		unitPositionRef.current = position;
		menuRef.current.open(ev.currentTarget as HTMLButtonElement);
	};

	const onInputChange = (ev: InputEvent) => {
		if (ev.isTrusted) {
			const input = ev.currentTarget as TextInputElement;
			const position = input.name;
			let top = topInputRef.current.value || '0';
			let right = rightInputRef.current.value || '0';
			let bottom = bottomInputRef.current.value || '0';
			let left = leftInputRef.current.value || '0';
			let newUnits = units;
			if (linked) {
				if (position === 'top') newUnits = [units[0], units[0], units[0], units[0]];
				else if (position === 'right') newUnits = [units[1], units[1], units[1], units[1]];
				else if (position === 'bottom') newUnits = [units[2], units[2], units[2], units[2]];
				else if (position === 'left') newUnits = [units[3], units[3], units[3], units[3]];
				bottom = input.value || '0';
				top = input.value || '0';
				right = input.value || '0';
				left = input.value || '0';
			} else if (linked === null) {
				if (position === 'top') {
					bottom = input.value || '0';
					newUnits = [units[0], units[1], units[0], units[3]];
				} else if (position === 'bottom') {
					top = input.value || '0';
					newUnits = [units[2], units[1], units[2], units[3]];
				} else if (position === 'left') {
					right = input.value || '0';
					newUnits = [units[0], units[3], units[0], units[3]];
				} else if (position === 'right') {
					left = input.value || '0';
					newUnits = [units[0], units[2], units[0], units[2]];
				}
			}
			setUnits(newUnits);
			top = newUnits[0] === 'auto' ? '' : top;
			right = newUnits[1] === 'auto' ? '' : right;
			bottom = newUnits[2] === 'auto' ? '' : bottom;
			left = newUnits[3] === 'auto' ? '' : left;
			setValue(
				`${top}${newUnits[0]} ${right}${newUnits[1]} ${bottom}${newUnits[2]} ${left}${newUnits[3]}`
			);
		}
	};

	const values = (value || '    ').split(' ').map((el) => {
		const val = parseFloat(el);
		if (isNaN(val)) return '';
		return val.toString();
	});

	useExposed({
		_$emForm: true,
		value: value,
		setValue: (newValue) => {
			const values = (newValue || '0px 0px 0px 0px').split(' ');
			const units = values.map((val) => val.match(/([\d\.]*)(.*)/)[2]);
			setUnits(units);
			setValue(newValue);
		},
		name: name,
		disabled: disabled,
		readonly: readonly,
		validate: () => {},
	});

	return html`
    <label class=${s.label}>${label}</label>
    <div class=${s.box}>
      <div class=${s.column}>
        <${TextInput}
					autocomplete="off"
          ref=${topInputRef}
          name="top"
          label="Top"
          type="number"
					readonly=${units[0] === 'auto'}
          value=${values[0]}
					trailingIcon=${canChangeUnit ? html`<i class=${s.icon}>${units[0]}</i>` : pxIcon}
					trailingIconClickCallback=${canChangeUnit && ((ev) => openMenu(ev, 'top'))}
          @input=${onInputChange}
        />
        <${TextInput}
					autocomplete="off"
          ref=${leftInputRef}
          name="left"
          label="Left"
          type="number"
					readonly=${units[3] === 'auto'}
          value=${values[3]}
					trailingIcon=${canChangeUnit ? html`<i class=${s.icon}>${units[3]}</i>` : pxIcon}
					trailingIconClickCallback=${canChangeUnit && ((ev) => openMenu(ev, 'left'))}
          @input=${onInputChange}
        />
      </div>
      <${IconButton}
        color="dark"
        variant=${linked ? 'filled' : linked === null ? 'outlined' : 'text'}
        @click=${() => {
					if (linked) {
						setLinked(false);
					} else if (linked === null) {
						setLinked(true);
					} else {
						setLinked(null);
					}
				}}
      >
        ${linkIcon}
      </${IconButton}>
      <div class=${s.column}>
        <${TextInput}
					autocomplete="off"
          ref=${bottomInputRef}
          name="bottom"
          label="Bottom"
          type="number"
					readonly=${units[2] === 'auto'}
          value=${values[2]}
					trailingIcon=${canChangeUnit ? html`<i class=${s.icon}>${units[2]}</i>` : pxIcon}
					trailingIconClickCallback=${canChangeUnit && ((ev) => openMenu(ev, 'bottom'))}
          @input=${onInputChange}
        />
        <${TextInput}
					autocomplete="off"
          ref=${rightInputRef}
          name="right"
          label="Right"
          type="number"
					readonly=${units[1] === 'auto'}
          value=${values[1]}
					trailingIcon=${canChangeUnit ? html`<i class=${s.icon}>${units[1]}</i>` : pxIcon}
					trailingIconClickCallback=${canChangeUnit && ((ev) => openMenu(ev, 'right'))}
          @input=${onInputChange}
        />
      </div>
    </div>
		
		<${Menu} dense rounded appendTo=${document.body} ref=${menuRef}>
      <${MenuItem} @click=${onUnitChanged} value="auto">auto</${MenuItem}>
      <${MenuItem} @click=${onUnitChanged} value="px">px</${MenuItem}>
      <${MenuItem} @click=${onUnitChanged} value="%">%</${MenuItem}>
      <${MenuItem} @click=${onUnitChanged} value="vh">vh</${MenuItem}>
      <${MenuItem} @click=${onUnitChanged} value="vw">vw</${MenuItem}>
    </${Menu}>
  `;
}

BoxInput.css = `
  .label {
    margin: 10px 0;
    display: block;
    font-weight: 500;
  }
  .box {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .column {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
	.icon {
    color: var(--em-color-dark);
    font-size: 12px;
  }
`;

defineWompo(BoxInput, { name: 'em-builder-box-input' });
