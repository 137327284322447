import { html } from 'wompo';
import { BuilderContextI, Widget, WidgetRenderer } from '../builder.js';
import RichTextEditor from '../form-items/rich-text-editor.js';

const ButtonWidgetRenderer: WidgetRenderer = (props, ctx, view, position) => {
	const { elemClass, content } = props;
	const formattedText = {
		parts: [`<span>${content}</span>`],
		values: [],
		_$wompoHtml: true,
	};
	return html`
		${!view &&
		html`<${RichTextEditor}
			simple
			selector="button span"
			position=${position}
			props=${props}
			widgetId="button"
			context=${ctx}
		/>`}
		<button class="emb-button ${elemClass}">${view ? formattedText : html`<span></span>`}</button>
	`;
};

function ButtonWidgetResult({ content, link, elemClass }: any) {
	return html`<@buttonWidget content=${content} link=${link} elemClass=${elemClass} />`;
}

ButtonWidgetRenderer.css = `
  .emb-button {
		cursor: pointer;
		display: block;
		font-family: inherit;
  }
`;

const ButtonWidget: Widget = {
	id: 'button',
	label: 'Button',
	structure: false,
	icon: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		class="bi bi-app"
		viewBox="0 0 16 16"
	>
		<path
			d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"
		/>
	</svg>`,
	renderer: ButtonWidgetRenderer,
	result: ButtonWidgetResult,
	manualStyles: true,
	defaultValues: {
		content: '<span>Button</span>',
		css: {
			style: {
				borderWidth: '1px 1px 1px 1px',
				borderColor: 'var(--color-primary)',
				borderStyle: 'solid',
				color: 'var(--color-primary)',
				backgroundColor: 'transparent',
				padding: '10px 20px 10px 20px',
				borderRadius: '30px 30px 30px 30px',
			},
			styleHovered: {
				color: 'var(--color-on-primary)',
				backgroundColor: 'var(--color-primary)',
			},
		},
	},
	editing: null,
};

export default ButtonWidget;
