import { html } from 'wompo';
import Dropper from '../dropper.js';
import useElements from '../hooks/useElements.js';
import { BuilderContextI } from '../builder.js';
import Select from 'emcomp/library/components/select/select.js';
import MenuItem from 'emcomp/library/components/menu/menu-item.js';

function ContainerWidgetRenderer(
	{ items = [], maxWidth }: any,
	context: BuilderContextI,
	view: boolean,
	position: string
) {
	const elements = useElements(items, position, view, context);

	return html`
		<div class="container ${maxWidth}">
			${elements} ${!view && html`<${Dropper} position=${`${position}.${items.length}`} />`}
		</div>
	`;
}

ContainerWidgetRenderer.css = `
	.container {
    display: block;
    position: relative;
  }
  .container {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  /* Extra Small */
  @media (width < 576px){
    .container {
      padding: 0 20px;
    }
  }

  /* Small */
  @media (width >= 576px){
    .container {
      max-width: 540px;
    }
    .container.xs {
      max-width: 540px;
    }
  }

  /* Medium */
  @media only screen and (width >= 768px){
    .container {
      max-width: 720px;
    }
    .container.xs {
      max-width: 540px;
    }
    .container.sm {
      max-width: 540px;
    }
  }

  /* Large */
  @media (width >= 992px){
    .container {
      max-width: 960px;
    }
    .container.xs {
      max-width: 540px;
    }
    .container.sm {
      max-width: 540px;
    }
    .container.md {
      max-width: 720px;
    }
  }

  /* Extra Large */
  @media (width >= 1200px){ 
    .container {
      max-width: 1140px;
    }
    .container.xs {
      max-width: 540px;
    }
    .container.sm {
      max-width: 540px;
    }
    .container.md {
      max-width: 720px;
    }
    .container.lg {
      max-width: 960px;
    }
  }
`;

const ContainerEditing = html`
	<${Select} name="maxWidth" label="Max Width" appendMenuTo=${document.body}>
		<${MenuItem} value=""><i>None</i></${MenuItem}>
		<${MenuItem} value="xs">xs</${MenuItem}>
		<${MenuItem} value="sm">sm</${MenuItem}>
		<${MenuItem} value="md">md</${MenuItem}>
		<${MenuItem} value="lg">lg</${MenuItem}>
		<${MenuItem} value="xl">xl</${MenuItem}>
	</${Select}>
`;

const ContainerWidget = {
	id: 'container',
	structure: true,
	icon: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		class="bi bi-textarea"
		viewBox="0 0 16 16"
	>
		<path
			d="M1.5 2.5A1.5 1.5 0 0 1 3 1h10a1.5 1.5 0 0 1 1.5 1.5v3.563a2 2 0 0 1 0 3.874V13.5A1.5 1.5 0 0 1 13 15H3a1.5 1.5 0 0 1-1.5-1.5V9.937a2 2 0 0 1 0-3.874zm1 3.563a2 2 0 0 1 0 3.874V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V9.937a2 2 0 0 1 0-3.874V2.5A.5.5 0 0 0 13 2H3a.5.5 0 0 0-.5.5zM2 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2m12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
		/>
	</svg>`,
	label: 'Container',
	renderer: ContainerWidgetRenderer,
	defaultValues: { items: [], maxWidth: '' },
	editing: ContainerEditing,
};

export default ContainerWidget;
