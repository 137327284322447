import { defineWompo, html, WompoProps } from 'wompo';

export default function InactiveBackground({ styles: s }: WompoProps) {
	return html`<div class=${s.surface}></div>`;
}

InactiveBackground.css = `
	.surface {
		position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: repeating-linear-gradient(
      45deg,
      #a4a4a440,
      #a4a4a440 10px,
      #80808040 10px,
      #80808040 20px
    );
    border-radius: 16px;
	}
`;

defineWompo(InactiveBackground, { name: 'em-builder-inactive-background' });
