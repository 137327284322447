import { html } from 'wompo';
import { WidgetRenderer } from '../builder.js';
import TextArea from 'emcomp/library/components/text-area/text-area.js';

const HtmlWidgetRenderer: WidgetRenderer = ({ html: code }: any) => {
	const formattedText = {
		parts: [code],
		values: [],
		_$wompoHtml: true,
	};
	return html`${formattedText}`;
};

const icon = html`
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		class="bi bi-code"
		viewBox="0 0 16 16"
	>
		<path
			d="M5.854 4.854a.5.5 0 1 0-.708-.708l-3.5 3.5a.5.5 0 0 0 0 .708l3.5 3.5a.5.5 0 0 0 .708-.708L2.707 8zm4.292 0a.5.5 0 0 1 .708-.708l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708L13.293 8z"
		/>
	</svg>
`;

const HtmlWidget = {
	id: 'html',
	structure: false,
	icon: icon,
	label: 'HTML',
	renderer: HtmlWidgetRenderer,
	defaultValues: { html: '<pre>Type your HTML</pre>' },
	editing: html`<${TextArea} style="min-height: 500px;" name="html" label="HTML" />`,
};

export default HtmlWidget;
