import {
	defineWompo,
	html,
	useContext,
	useExposed,
	useMemo,
	useState,
	WompoElement,
	WompoProps,
} from 'wompo';
import { BuilderContext, Widget, WidgetItem } from './builder.js';
import Dropper from './dropper.js';
import { CanvasContext } from './canvas.js';
import {
	duplicateIcon,
	editAltIcon,
	editIcon,
	globeIcon,
	layersIcon,
	moveIcon,
	settingsIcon,
	trashIcon,
} from './icons.js';
import { handleStyleObj } from './getHtml.js';

export interface BuilderElementProps extends WompoProps {
	position: string;
	widget: Widget;
	widgetProps: any;
}

export interface BuilderElementElement extends WompoElement<BuilderElementProps> {
	widget: Widget;
	widgetProps: any;
	edit: (data: WidgetItem) => void;
	removeItem: () => void;
}

export default function BuilderElement({
	position,
	widget,
	widgetProps,
	styles: s,
}: BuilderElementProps) {
	const [hovered, setHovered] = useState(false);
	const [droppable, setDroppable] = useState(false);
	const { insert, edit, remove, move, duplicate, openMenu } = useContext(CanvasContext);

	const isGlobal = typeof widget.renderer !== 'function';
	const elemClass = `e${position.replace(/\./g, '-')}`;

	const builderCtx = useContext(BuilderContext);

	const toRender = useMemo(() => {
		let widgetRendered = widget;
		if (builderCtx.treeMode && !widget.structure) return null;
		if (isGlobal) widgetRendered = builderCtx.registry[(widget.renderer as WidgetItem).id];
		if (widget.manualStyles) widgetProps.elemClass = elemClass;
		let toRender = (widgetRendered as any).renderer(widgetProps, builderCtx, isGlobal, position);
		if (typeof toRender === 'string') {
			toRender = {
				parts: [toRender],
				values: [],
				_$wompoHtml: true,
			};
		}
		return toRender;
	}, [
		widget,
		widgetProps,
		position,
		builderCtx.pageSettings,
		builderCtx.treeMode,
		builderCtx.structureMode,
	]);

	const onMouseEnter = () => {
		if (!builderCtx.isDragging) setHovered(true);
	};
	const onMouseLeave = () => {
		setHovered(false);
		setDroppable(false);
	};

	const onDragStart = () => {
		setTimeout(() => {
			builderCtx.startElementDrag(this);
			this.style.display = 'none';
		});
	};

	const onDragEnd = () => {
		this.style.display = 'block';
		builderCtx.endElementDrag();
	};

	const allowDrop = (ev: DragEvent) => {
		ev.preventDefault();
		setDroppable(true);
	};

	const onDragleave = () => {
		setDroppable(false);
	};

	const onInsert = () => {
		const isWidget = !!builderCtx.draggingWidget.current;
		const widget = isWidget
			? builderCtx.draggingWidget.current
			: builderCtx.draggingElement.current.props.widget;
		const isGlobal = typeof widget.renderer !== 'function';
		if (isWidget)
			insert(
				position,
				widget,
				isGlobal
					? { ...(widget.renderer as WidgetItem), id: widget.id }
					: { id: widget.id, props: widget.defaultValues }
			);
		else
			move(builderCtx.draggingElement.current.props.position, position, widget, {
				id: widget.id,
				props: builderCtx.draggingElement.current.props.widgetProps,
			});
		setDroppable(false);
	};

	const addGlobalWidget = async () => {
		const newId = await builderCtx.editWidgets(
			{
				id: widget.id,
				props: structuredClone(widgetProps),
			},
			widget
		);
		if (newId) {
			edit(position, {
				id: newId as string,
				props: widgetProps,
			});
		}
	};

	const editGlobal = async () => {
		builderCtx.editWidgets(widget);
	};

	const separate = () => {
		edit(position, {
			id: (widget.renderer as WidgetItem).id,
			props: widgetProps,
		});
	};

	const openItemMenu = (ev: MouseEvent) => {
		openMenu(ev.currentTarget as HTMLElement, { id: widget.id, props: widgetProps }, position);
		setTimeout(() => {
			setHovered(true);
		});
	};

	useExposed({
		widgetProps: widgetProps,
		widget: widget,
		edit: (data: WidgetItem) => edit(position, data),
		removeItem: () => remove(position),
	});

	const elementClasses = `${s.element} ${hovered && s.hovered} ${widget.structure && s.structure}`;

	const elemId = 'p' + position.replace(/\./g, '-');
	let styles = `.${elemId} ${widgetProps.css?.raw?.replace(/}/g, `} .${elemId}`)}`;
	styles = styles.substring(0, styles.length - elemId.length + 1);

	return html`
		${builderCtx.isDragging &&
		!widget.hidden &&
		(!builderCtx.editingGlobal || position !== '0') &&
		html`<div
			class="${s.dropper} ${widget.structure && s.structure}"
			@dragover=${allowDrop}
			@dragleave=${onDragleave}
			@drop=${onInsert}
		></div>`}
		${!widget.hidden &&
		(!builderCtx.editingGlobal || position !== '0') &&
		html`
			<${Dropper}
				position=${position}
				droppable=${droppable}
				style=${{
					overflow: 'hidden',
					maxHeight: droppable ? '100px' : '0px',
					padding: droppable ? '24px' : '0 24px',
				}}
			/>
		`}
		<div
			class="${elementClasses} ${!builderCtx.treeMode &&
			widgetProps.css?.class} ${builderCtx.treeMode && s.tree} ${builderCtx.structureMode &&
			s.box} ${elemId} ${isGlobal && s.composed} ${!builderCtx.treeMode &&
			!widget.manualStyles &&
			elemClass}"
			id=${!builderCtx.treeMode && widgetProps.css?.id}
			@mouseenter=${onMouseEnter}
			@mouseleave=${onMouseLeave}
		>
			<div class=${s.actions}>
				${!widget.hidden &&
				html`
					<button class=${s.btn} draggable="true" @dragstart=${onDragStart} @dragend=${onDragEnd}>
						${moveIcon}
					</button>
				`}
				${!isGlobal &&
				html`<button class=${s.btn} @click=${() => builderCtx.openItemEditing(this)}>
					${editIcon}
				</button>`}
				${!widget.hidden &&
				html`
					<button class=${s.btn} @click=${() => duplicate(position)}>${duplicateIcon}</button>
				`}
				${!isGlobal &&
				!builderCtx.editingGlobal &&
				!widget.hidden &&
				html` <button class=${s.btn} @click=${addGlobalWidget}>${globeIcon}</button> `}
				${isGlobal &&
				!builderCtx.editingGlobal &&
				html`
					<button class=${s.btn} @click=${separate}>${layersIcon}</button>
					<button class=${s.btn} @click=${editGlobal}>${editAltIcon}</button>
				`}
				${builderCtx.editingItem !== this &&
				html`<button class=${s.btn} @click=${() => remove(position)}>${trashIcon}</button>`}
				<button class=${s.btn} @click=${openItemMenu}>${settingsIcon}</button>
			</div>
			${builderCtx.treeMode && html`<div class=${s.widgetLabel}>${widget.label}</div>`}${toRender}
		</div>
		<style>
			${`
				body .${elemClass} {
					${handleStyleObj(widgetProps.css?.style)}
				}
				body .${elemClass}:hover {
					${handleStyleObj(widgetProps.css?.styleHovered)}
				}
			`}
		</style>
		${!builderCtx.treeMode &&
		widgetProps.css?.raw &&
		html`
			<style>
				${styles}
			</style>
		`}
	`;
}

BuilderElement.css = `
	:host {
		position: relative;
		display: block;
	}
	.dropper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: transparent;
		z-index: 30;
	}
	.dropper.structure {
		bottom: 98%;
		z-index: 40;
		top: -30px;
	}
	.element {
		display: block;
		position: relative;
		min-height: 30px;
	}
	.element.hovered:not(:has(> [class*="mce-edit-focus"])) {
		box-shadow: 0 0 0 3px var(--em-color-primary);
	}
	.element.hovered.structure:not(:has(> [class*="mce-edit-focus"])) {
		box-shadow: 0 0 0 2px var(--em-color-secondary-transparent);
	}
	.element.hovered.composed:not(:has(> [class*="mce-edit-focus"])) {
		box-shadow: 0 0 0 2px var(--em-color-success);
	}
	:not(.hovered) > .actions,
	.element:has(> [class*="mce-edit-focus"]) .actions {
		display: none;
	}
	.actions {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		overflow: hidden;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
		padding: 0 10px;
		background-color: var(--em-color-primary);
		color: var(--em-color-on-primary);
		z-index: 10;
	}
	.structure > .actions {
		left: -2px;
		top: -30px;
		transform: none;
		background-color: var(--em-color-secondary);
		color: var(--em-color-on-secondary);
		z-index: 11;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	.element.composed > .actions {
		right: 0;
		left: unset;
		transform: none;
		background-color: var(--em-color-success);
		color: var(--em-color-on-success);
		z-index: 12;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: 12px;
		border-top-left-radius: 0;
	}
	.btn {
		border: none;
		position: relative;
		padding: 5px;
		background-color: var(--em-color-primary);
		color: var(--em-color-on-primary);
		cursor: pointer;
	}
	.btn:hover {
		background-color: var(--em-color-primary-dark);
		color: var(--em-color-on-primary-dark);
	}
	.structure > div > .btn {
		background-color: var(--em-color-secondary);
		color: var(--em-color-on-secondary);
	}
	.structure > div > .btn:hover {
		background-color: var(--em-color-secondary-dark);
		color: var(--em-color-on-secondary-dark);
	}
	.composed > div > .btn {
		background-color: var(--em-color-success);
		color: var(--em-color-on-success);
	}
	.composed > div > .btn:hover {
		background-color: var(--em-color-success-dark);
		color: var(--em-color-on-success-dark);
	}

	.structure:not(.tree) .structure > .actions {
		top: 100%;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	.structure:not(.tree) .structure .structure > .actions {
		left: unset;
		right: 0;
	}

	.box:not(.tree) {
		border: 1px dashed var(--em-color-primary);
	}
	.box:not(.tree):hover {
		border-radius: 0px;
	}
	.box.structure:not(.tree) {
		border: 1px dashed var(--em-color-secondary);
	}
	.box.composed:not(.tree) {
		border: 1px dashed var(--em-color-success);
	}
	.box:not(.tree):not(.structure) > .actions {
		top: -2px;
	}
	.box:not(.tree).composed > .actions {
		border-top-right-radius: 0;
		right: -2px;
	}

	.tree > .actions {
		position: relative;
		display: flex;
		margin: 0;
		left: 0;
		right: 0;
		transform: none;
		z-index: 10;
		padding: 0;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.widgetLabel {
		padding: 5px 0 10px 0;
		display: flex;
		text-align: center;
		justify-content: center;
		font-family: Montserrat, Arial;
	}
	.tree {
		margin: 10px 0;
		box-shadow: 0 0 0 3px var(--em-color-primary);
		border-radius: 16px;
	}
	.tree em-builder-dropper {
		padding: 0;
	}
	.tree.structure {
		box-shadow: 0 0 0 2px var(--em-color-secondary-transparent);
		background-color: transparent;
		padding: 0 20px 20px 20px;
	}
	.tree.structure > .actions {
		position: absolute;
		margin: 0;
		top: 0;
		left: 0;
		right: unset;
		z-index: 11;
		border-top-left-radius: 16px;
		border-top-right-radius: 0;
	}
	.tree.structure > .widgetLabel {
		background-color: var(--em-color-secondary);
		color: var(--em-color-on-secondary);
		margin: 0 -20px;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		margin-bottom: 20px;
		line-height: 1;
	}
	.tree.composed {
		box-shadow: 0 0 0 2px var(--em-color-success);
		background-color: transparent;
	}
	.tree.element.composed.structure > .actions {
		position: absolute;
		margin: 0;
		top: 0;
		left: 0;
		left: unset;
		z-index: 12;
		border-top-right-radius: 16px;
		border-top-left-radius: 0;
	}
	.tree.element.composed > .actions {
		position: relative;
		display: flex;
		margin: 5px 0;
		left: 0;
		right: 0;
		transform: none;
		z-index: 12;
		padding: 0;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.element.tree.composed.structure > .widgetLabel {
		background-color: var(--em-color-success);
		color: var(--em-color-on-success);
	}
`;

defineWompo(BuilderElement, { name: 'em-builder-element' });
