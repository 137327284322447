import { Widget, WidgetItem } from '../builder.js';

const getScriptsObj = (
	items: WidgetItem[],
	registry: { [id: string]: Widget },
	scripts: { [id: string]: string } = {}
) => {
	if (!items) return {};
	items.forEach((item) => {
		let renderer = registry[item.id]?.renderer;
		if (typeof renderer !== 'function') renderer = registry[renderer.id]?.renderer;
		if (typeof renderer === 'function' && (renderer as any).scripts) {
			scripts[item.id] = (renderer as any).scripts;
		}
		if (item.props.items) getScriptsObj(item.props.items, registry, scripts);

		if (typeof registry[item.id]?.renderer !== 'function') {
			getScriptsObj((registry[item.id]?.renderer as WidgetItem).props.items, registry, scripts);
		}
	});
};

export default function useWidgetsScripts(
	items: WidgetItem[],
	registry: { [id: string]: Widget },
	layout: WidgetItem[] = []
) {
	if (!items) return '';
	const scriptsObj = getScriptsObj(items, registry);
	const layoutObj = getScriptsObj(layout, registry);
	let scriptsString = '';
	for (const widgetId in scriptsObj) {
		if (!layoutObj[widgetId]) scriptsString += scriptsObj[widgetId];
	}
	return scriptsString;
}
