import { html } from 'wompo';
import useElements from '../hooks/useElements.js';
import { BuilderContextI } from '../builder.js';
import Dropper from '../dropper.js';

function BuilderBodyRenderer(
	{ items = [] }: any,
	context: BuilderContextI,
	view: boolean,
	position: string
) {
	const elements = useElements(items, position, view, context);
	return html`
		<div id="builder-body">${elements}</div>
		${context.layout
			? !view && html`<${Dropper} position=${`${position}.${items.length}`} />`
			: html`<div style="text-align: center;">CONTENT</div>`}
	`;
}

const BuilderBody = {
	id: 'builder-body',
	group: 'Layout',
	structure: true,
	icon: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		class="bi bi-grid-1x2-fill"
		viewBox="0 0 16 16"
	>
		<path
			d="M0 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm9 0a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1zm0 9a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z"
		/>
	</svg>`,
	label: 'Builder Body',
	renderer: BuilderBodyRenderer,
	defaultValues: { items: [] },
	editing: null,
};

export default BuilderBody;
