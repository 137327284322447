import GridItem from 'emcomp/library/components/grid/grid-item.js';
import Grid from 'emcomp/library/components/grid/grid.js';
import IconButton from 'emcomp/library/components/icon-button/icon-button.js';
import TextInput, { TextInputElement } from 'emcomp/library/components/text-input/text-input.js';
import { defineWompo, html, useExposed, useRef, useState, WompoProps } from 'wompo';

interface CornerBoxInputProps extends WompoProps {
	label?: string;
	name?: string;
	disabled?: boolean;
	readonly?: boolean;
	value?: string;
}

const pxIcon = html`<span style="color: var(--em-color-dark-light); font-size: 16px;">px</span>`;

const linkIcon = html`
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		class="bi bi-link"
		viewBox="0 0 16 16"
	>
		<path
			d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"
		/>
		<path
			d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"
		/>
	</svg>
`;

export default function CornerBoxInput({
	value: initialValue,
	label,
	name,
	disabled,
	readonly,
	styles: s,
}: CornerBoxInputProps) {
	const [value, setValue] = useState(initialValue);
	const [linked, setLinked] = useState(true);

	const topLeftInputRef = useRef<TextInputElement>();
	const topRightInputRef = useRef<TextInputElement>();
	const bottomRightInputRef = useRef<TextInputElement>();
	const bottomLeftInputRef = useRef<TextInputElement>();

	const onInputChange = (ev: InputEvent) => {
		if (ev.isTrusted) {
			let topLeft = topLeftInputRef.current.value || '0';
			let topRight = topRightInputRef.current.value || '0';
			let bottomRight = bottomRightInputRef.current.value || '0';
			let bottomLeft = bottomLeftInputRef.current.value || '0';
			const input = ev.currentTarget as TextInputElement;
			if (linked) {
				topLeft = input.value || '0';
				topRight = input.value || '0';
				bottomRight = input.value || '0';
				bottomLeft = input.value || '0';
			}
			setValue(`${topLeft}px ${topRight}px ${bottomRight}px ${bottomLeft}px`);
		}
	};

	const values = (value?.replaceAll('px', '') ?? '0 0 0 0').split(' ');

	useExposed({
		_$emForm: true,
		value: value,
		setValue: setValue,
		name: name,
		disabled: disabled,
		readonly: readonly,
		validate: () => {},
	});

	return html`
		<label class=${s.label}>${label}</label>
    <div class=${s.box}>
      <div class=${s.column}>
        <${TextInput}
          autocomplete="off"
          ref=${topLeftInputRef}
          name="top-left"
          label="Top-Left"
          type="number"
          trailingIcon=${pxIcon}
          value=${values[0]}
          @input=${onInputChange}
        />
        <${TextInput}
          autocomplete="off"
          ref=${bottomLeftInputRef}
          name="bottom-left"
          label="Bottom-Left"
          type="number"
          trailingIcon=${pxIcon}
          value=${values[3]}
          @input=${onInputChange}
        />
      </div>
      <${IconButton}
        color="dark"
        variant=${linked ? 'filled' : 'text'}
        @click=${() => setLinked(!linked)}
      >
        ${linkIcon}
      </${IconButton}>
      <div class=${s.column}>
        <${TextInput}
          autocomplete="off"
          ref=${topRightInputRef}
          name="top-right"
          label="Top-right"
          type="number"
          trailingIcon=${pxIcon}
          value=${values[1]}
          @input=${onInputChange}
        />
        <${TextInput}
          autocomplete="off"
          ref=${bottomRightInputRef}
          name="bottom-right"
          label="Bottom-Right"
          type="number"
          trailingIcon=${pxIcon}
          value=${values[2]}
          @input=${onInputChange}
        />
      </div>
    </div>
	`;
}

CornerBoxInput.css = `
  .label {
    margin: 10px 0;
    display: block;
    font-weight: 500;
  }
  .box {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .column {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

defineWompo(CornerBoxInput, { name: 'em-builder-corner-box-input' });
