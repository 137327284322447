import { html } from 'wompo';
import { BuilderContextI, WidgetItem } from '../builder.js';
import BuilderElement from '../builder-element.js';
import { googleFonts } from '../fonts.js';
import { handleStyleObj } from '../getHtml.js';

const buildViewElem = (context: BuilderContextI, item: any, position: string, index: number) => {
	const widget = context.registry[item.id];
	const pos = `${position ? position + '.' : ''}${index}`;
	let toRender: any = null;
	let renderer: any = widget.renderer;
	if (typeof renderer !== 'function')
		renderer = context.registry[(widget.renderer as WidgetItem).id].renderer;
	const isGlobal = typeof widget.renderer !== 'function';
	const widgetProps = isGlobal ? (widget.renderer as WidgetItem).props : item.props;
	const elemClass = `e${pos.replace(/\./g, '-')}`;
	if (widget.manualStyles) widgetProps.elemClass = elemClass;
	if (!context.resultMode || typeof widget.result !== 'function') {
		if (context.resultMode && isGlobal && typeof context.globalWidgetRenderer === 'function') {
			toRender = context.globalWidgetRenderer(widget, context, pos);
		} else {
			toRender = renderer(
				widgetProps,
				context,
				item._$view && item.id === 'builder-body' && !context.view ? false : true,
				pos
			);
		}
	} else {
		toRender = widget.result(widgetProps, context, pos);
	}
	const classes = widgetProps.css?.class;
	const elemCustomId = widgetProps.css?.id;
	const htmlString = {
		parts: [toRender],
		values: [],
		_$wompoHtml: true,
	};
	const elemId = 'p' + pos.replace(/\./g, '-');
	let styles = `.${elemId} ${widgetProps.css?.raw?.replace(/}/g, `} .${elemId}`)}`;
	styles = styles.substring(0, styles.length - elemId.length + 1);
	return html`
		${context.resultMode &&
		googleFonts.includes(widgetProps.css?.style?.fontFamily) &&
		html`
			<link
				rel="stylesheet"
				href=${`https://fonts.googleapis.com/css2?family=${widgetProps.css?.style?.fontFamily.replace(
					/\s/g,
					'+'
				)}&display=swap`}
			/>
		`}
		<div class="${classes} ${elemId} ${!widget.manualStyles && elemClass}" id=${elemCustomId}>
			${typeof toRender === 'string' ? htmlString : toRender}
		</div>
		<style>
			${`
				body .${elemClass} {
					${handleStyleObj(widgetProps.css?.style)}
				}
				body .${elemClass}:hover {
					${handleStyleObj(widgetProps.css?.styleHovered)}
				}
			`}
		</style>
		${widgetProps.style &&
		html`<style>
			${styles}
		</style>`}
	`;
};

const buildEditElem = (context: BuilderContextI, item: any, position: string, index: number) => {
	const widget = context.registry[item.id];
	const isGlobal = typeof widget.renderer !== 'function';
	const widgetProps = isGlobal ? (widget.renderer as WidgetItem).props : item.props;
	const toRender = html`
		<${BuilderElement}
			position=${`${position ? position + '.' : ''}${index}`}
			widget=${widget}
			widgetProps=${widgetProps}
		/>
	`;
	return toRender;
};

const buildElementsOnView = (context: BuilderContextI, items: any[], position: string) => {
	return items.map((item, i) => {
		return buildViewElem(context, item, position, i);
	});
};

const buildEditingItems = (context: BuilderContextI, items: any[], position: string) => {
	return items.map((item, i) => {
		if (item._$view) {
			return buildViewElem(context, item, position, i);
		}
		return buildEditElem(context, item, position, i);
	});
};

export default function useElements(
	items: any[],
	position: string,
	view: boolean,
	context: BuilderContextI
) {
	const buildElements = () => {
		if (view) return buildElementsOnView(context, items, position);
		return buildEditingItems(context, items, position);
	};
	const elements = buildElements();

	return elements;
}
