import TextInput, { TextInputElement } from 'emcomp/library/components/text-input/text-input.js';
import { defineWompo, html, useExposed, useRef, useState, WompoProps } from 'wompo';

interface GridEditorProps extends WompoProps {
	disabled?: boolean;
	readonly?: boolean;
	required?: boolean;
	name?: string;
}

export default function GridEditor({ name, disabled, readonly, required }: GridEditorProps) {
	const [value, setValue] = useState([]);

	const inputRef = useRef<TextInputElement>();

	const validate = () => {
		return inputRef.current.validate();
	};

	const handleChange = (ev: InputEvent) => {
		const target = ev.currentTarget as TextInputElement;
		const nColumns = parseInt(target.value);
		if (nColumns > 0 && nColumns <= 6) {
			if (value.length > nColumns) {
				setValue((oldValue) => {
					return oldValue.filter((_, i) => i < nColumns);
				});
			} else {
				const toAdd = new Array(nColumns - value.length).fill({
					id: 'column-item',
					props: { items: [] },
				});
				setValue((oldValue) => {
					return [...oldValue, ...toAdd];
				});
			}
		}
	};

	useExposed({
		_$emForm: true,
		value: value,
		setValue: (newValue: any[]) => {
			inputRef.current.setValue(newValue.length.toString());
			setValue(structuredClone(newValue));
		},
		disabled: disabled,
		readonly: readonly,
		name: name,
		required: required,
		validate: validate,
	});

	return html`
		<${TextInput}
			ref=${inputRef}
			type="number"
			min="1"
			max="4"
			value=${0}
			label="Number of columns"
			@input=${handleChange}
		/>
	`;
}

defineWompo(GridEditor, { name: 'em-builder-grid-editor' });
