import { defineWompo, html, WompoProps } from 'wompo';
import useElements from './hooks/useElements.js';
import { BuilderContextI, getBodyItems } from './builder.js';

export interface HtmlResultProps extends WompoProps {
	items: any[];
	context: BuilderContextI;
}

export default function HtmlResult({ items: fullItems, context }: HtmlResultProps) {
	const ctx = {
		layout: context.layout,
		registry: context.registry,
		pageSettings: context.pageSettings,
		isDragging: context.isDragging,
		globalWidgetRenderer: context.globalWidgetRenderer,
		view: true,
		lang: context.lang,
		resultMode: true,
	} as Partial<BuilderContextI>;

	const items = context.layout ? getBodyItems(context.layout?.items, fullItems) : fullItems;

	const elements = useElements(items, null, true, ctx as any);

	return html`${elements}`;
}

defineWompo(HtmlResult, { name: 'em-builder-html-result' });
