import { defineWompo, html, useEffect, WompoProps } from 'wompo';

interface WidgetBoxProps extends WompoProps {
	icon: any;
	label: string;
	global: boolean;
}

export default function WidgetBox({ icon, label, global, styles: s }: WidgetBoxProps) {
	useEffect(() => {
		if (global) this.classList.add(s.global);
		else this.classList.remove(s.global);
	}, [global]);
	return html`
		<i class=${s.icon}>${icon}</i>
		<span class=${s.label}>${label}</span>
	`;
}

WidgetBox.css = `
  :host {
    display: inline-block;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 16px;
    border: 1px solid var(--em-color-primary);
    background-color: var(--em-color-primary-bg);
    color: var(--em-color-on-primary-bg);
    height: 80px;
    cursor: move;
    text-align: center;
  }
  :host:hover {
    background-color: var(--em-color-primary-bg-dark);
    color: var(--em-color-on-primary-bg);
  }
  :host.global {
    border: 1px solid var(--em-color-success);
    background-color: var(--em-color-success-bg);
    color: var(--em-color-on-success-bg);
  }
  :host.global:hover {
    background-color: var(--em-color-success-bg-dark);
    color: var(--em-color-on-success-bg);
  }
`;

defineWompo(WidgetBox, { name: 'em-builder-widget-box' });
