import { html } from 'wompo';
import Dropper from '../dropper.js';
import useElements from '../hooks/useElements.js';
import { BuilderContextI, Widget } from '../builder.js';
import Select from 'emcomp/library/components/select/select.js';
import MenuItem from 'emcomp/library/components/menu/menu-item.js';
import TextInput from 'emcomp/library/components/text-input/text-input.js';

function FlexWidgetRenderer(
	{ items = [], justifyContent, alignItems, gap, flexDirection }: any,
	context: BuilderContextI,
	view: boolean,
	position: string
) {
	const elements = useElements(items, position, view, context);

	const dropperStyles: Partial<CSSStyleDeclaration> = {
		minWidth: '200px',
		padding: '0',
	};

	return html`
		<div class="flex" style=${{ justifyContent, alignItems, gap, flexDirection }}>
			${elements}
			${!view &&
			html`<${Dropper} style=${dropperStyles} position=${`${position}.${items.length}`} />`}
		</div>
	`;
}

FlexWidgetRenderer.css = `
  .flex {
    display: flex;
  }
`;

const FlexWidget: Widget = {
	id: 'flex',
	label: 'Flex',
	structure: true,
	icon: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		class="bi bi-distribute-horizontal"
		viewBox="0 0 16 16"
	>
		<path
			fill-rule="evenodd"
			d="M14.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5m-13 0a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5"
		/>
		<path d="M6 13a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1z" />
	</svg>`,
	renderer: FlexWidgetRenderer,
	defaultValues: {
		items: [],
		justifyContent: 'start',
		alignItems: 'stretch',
		gap: 10,
		flexDirection: 'row',
	},
	manualStyles: true,
	editing: html`
    <${Select} appendMenuTo=${document.body} label="Justify content" name="justifyContent">
      <${MenuItem} value="start">Start</${MenuItem}>
      <${MenuItem} value="center">Center</${MenuItem}>
      <${MenuItem} value="end">End</${MenuItem}>
      <${MenuItem} value="space-around">Space around</${MenuItem}>
      <${MenuItem} value="space-between">Space between</${MenuItem}>
      <${MenuItem} value="space-evenly">Space Evenly</${MenuItem}>
    </${Select}>
    <${Select} appendMenuTo=${document.body} label="Align items" name="alignItems">
      <${MenuItem} value="start">Start</${MenuItem}>
      <${MenuItem} value="center">Center</${MenuItem}>
      <${MenuItem} value="end">End</${MenuItem}>
      <${MenuItem} value="space-around">Space around</${MenuItem}>
      <${MenuItem} value="space-between">Space between</${MenuItem}>
      <${MenuItem} value="stretch">Stretch</${MenuItem}>
      <${MenuItem} value="baseline">Baseline</${MenuItem}>
      <${MenuItem} value="normal">Normal</${MenuItem}>
    </${Select}>
    <${TextInput} type="number" label="Gap" name="gap" />
    <${Select} appendMenuTo=${document.body} label="Direction" name="flexDirection">
      <${MenuItem} value="row">Row</${MenuItem}>
      <${MenuItem} value="column">Column</${MenuItem}>
    </${Select}>
  `,
};

export default FlexWidget;
