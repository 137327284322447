import ColorPicker, {
	ColorPickerElement,
} from 'emcomp/library/components/color-picker/color-picker.js';
import {
	defineWompo,
	html,
	RefHook,
	useEffect,
	useExposed,
	useRef,
	useState,
	WompoProps,
} from 'wompo';

interface BuilderColorPickerProps extends WompoProps {
	label: string;
	name: string;
	palette: string[];
	withOpacity?: boolean;
	iframe: RefHook<HTMLIFrameElement>;
}

const colorNames = [
	'body',
	'primary',
	'secondary',
	'success',
	'info',
	'warning',
	'danger',
	'light',
	'dark',
	'white',
	'black',
];

export default function BuilderColorPicker({
	label,
	name,
	palette,
	iframe,
	withOpacity,
	styles: s,
}: BuilderColorPickerProps) {
	const [value, setValue] = useState<string>(null);
	const pickerRef = useRef<ColorPickerElement>();
	const settingValueRef = useRef(true);

	useEffect(() => {
		if (!settingValueRef.current) {
			this.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
			this.dispatchEvent(new Event('change', { bubbles: true, composed: true }));
		}
	}, [value]);

	const onColorCliked = (color: string, colorName?: string) => {
		settingValueRef.current = false;
		pickerRef.current.setValue(color, true);
		if (colorName) setValue(`var(--color-${colorName})`);
		else setValue(color);
	};

	const onColorChanged = (ev: InputEvent) => {
		settingValueRef.current = false;
		const target = ev.currentTarget as ColorPickerElement;
		setValue(target.value);
	};

	useExposed({
		_$emForm: true,
		value: value,
		setValue: (color: string | null) => {
			settingValueRef.current = true;
			if (!color?.startsWith('var(')) {
				pickerRef.current.setValue(color);
			} else if (color && iframe?.current) {
				const body = iframe.current.contentDocument.body;
				const variableName = color.match(/var\((--color-.*?)\)/)[1];
				const propertyValue = getComputedStyle(body).getPropertyValue(variableName);
				pickerRef.current.setValue(propertyValue);
			}
			setValue(color);
		},
		disabled: false,
		readonly: false,
		name: name,
		required: false,
		validate: () => true,
	});

	return html`
		<${ColorPicker}
			ref=${pickerRef}
			label=${label}
			name=em-core2-web
			canDelete=${true}
			withOpacity=${withOpacity}
			@input=${onColorChanged}
		/>
		<div class=${s.colors}>
			${palette.map((color, i) => {
				return html`<div
					class=${s.color}
					style=${{ backgroundColor: color }}
					@click=${() => onColorCliked(color, colorNames[i])}
				></div>`;
			})}
			<div class="${s.color} ${s.transparent}" @click=${() => onColorCliked('transparent')}></div>
		</div>
	`;
}

BuilderColorPicker.css = `
	:host {
		margin: 10px 0;
	}
  .colors {
    display: flex;
    gap: 5px;
  }
  .color {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .color:hover {
    transform: scale(1.1);
  }
	.transparent {
		background-color: #ffffff;
		position: relative;
	}
	.transparent:before {
		content: "";
		position: absolute;
		top: 0;
		left: 9px;
		border-radius: 10px;
		height: 20px;
		width: 3px;
		background-color: red;
		transform: rotate(-45deg);
		
	}
`;

defineWompo(BuilderColorPicker, { name: 'em-builder-color-picker' });
