import { defineWompo, html, useContext, WompoProps } from 'wompo';
import { BuilderContext, BuilderContextI } from './builder.js';
import useElements from './hooks/useElements.js';

interface RenderModeProps extends WompoProps {
	items: { id: string; props: any }[];
}

export default function RenderMode({ items, styles: s }: RenderModeProps) {
	const builderCtx = useContext(BuilderContext);
	const ctx = {
		layout: builderCtx.layout,
		registry: builderCtx.registry,
		pageSettings: builderCtx.pageSettings,
		isDragging: builderCtx.isDragging,
		view: true,
	} as Partial<BuilderContextI>;
	const elements = useElements(items, null, true, ctx as any);

	return html`
		<div class=${s.container} style=${builderCtx.pageSettings?.css}>${elements}</div>
		<style>
			* {
				box-sizing: border-box;
			}
			${builderCtx.pageSettings?.style}
		</style>
	`;
}

RenderMode.css = `
	.container {
		container: preview;
	}
`;

defineWompo(RenderMode, { name: 'em-builder-render-mode' });
