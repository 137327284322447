export const systemFonts = [
	'Arial',
	'Verdana',
	'Helvetica',
	'Times New Roman',
	'Courier New',
	'Georgia',
	'Palatino',
	'Garamond',
	'Bookman',
	'Comic Sans MS',
	'Trebuchet MS',
	'Arial Black',
	'Impact',
	'Lucida Sans',
	'Tahoma',
	'Century Gothic',
	'Lucida Console',
	'Monaco',
	'Brush Script MT',
	'Segoe UI',
	'Candara',
	'Optima',
	'Charcoal',
	'Futura',
	'Gill Sans',
	'Geneva',
	'Franklin Gothic Medium',
	'Consolas',
	'Menlo',
	'Didot',
	'Baskerville',
	'Courier',
	'Copperplate',
];

export const googleFonts = [
	'Amatic SC',
	'Anton',
	'Bebas Neue',
	'Cabin',
	'Cinzel',
	'Dancing Script',
	'Exo',
	'Fira Sans',
	'Josefin Sans',
	'Kanit',
	'Lato',
	'Lora',
	'Manrope',
	'Merriweather',
	'Montserrat',
	'Nunito',
	'Open Sans',
	'Orbitron',
	'Oswald',
	'PT Sans',
	'Permanent Marker',
	'Playfair Display',
	'Poppins',
	'Quicksand',
	'Raleway',
	'Roboto',
	'Source Code Pro',
	'Source Sans 3',
	'Ubuntu',
	'Vollkorn',
];

export const fonts = [...systemFonts, ...googleFonts];
