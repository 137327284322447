import { html } from 'wompo';
import Dropper from '../dropper.js';
import useElements from '../hooks/useElements.js';
import { BuilderContextI } from '../builder.js';

function DivWidgetRenderer(
	{ items = [] }: any,
	context: BuilderContextI,
	view: boolean,
	position: string
) {
	const elements = useElements(items, position, view, context);

	return html`
		${elements} ${!view && html`<${Dropper} position=${`${position}.${items.length}`} />`}
	`;
}

const DivWidget = {
	id: 'div',
	structure: true,
	icon: html`<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		class="bi bi-app"
		viewBox="0 0 16 16"
	>
		<path
			d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"
		/>
	</svg>`,
	label: 'Div',
	renderer: DivWidgetRenderer,
	defaultValues: { items: [] },
	editing: null,
};

export default DivWidget;
