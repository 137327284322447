import AccordionItem from 'emcomp/library/components/accordion/accordion-item.js';
import Accordion from 'emcomp/library/components/accordion/accordion.js';
import {
	defineWompo,
	html,
	RefHook,
	useEffect,
	useExposed,
	useRef,
	useState,
	WompoProps,
} from 'wompo';
import MeasureInput from './form-items/mesure-input.js';
import BoxInput from './form-items/box-input.js';
import CornerBoxInput from './form-items/corner-box-input.js';
import Select from 'emcomp/library/components/select/select.js';
import MenuItem from 'emcomp/library/components/menu/menu-item.js';
import TextInput from 'emcomp/library/components/text-input/text-input.js';
import ButtonGroup, {
	ButtonGroupElement,
} from 'emcomp/library/components/button-group/button-group.js';
import Button from 'emcomp/library/components/button/button.js';
import {
	alignCenterIcon,
	alignLeftIcon,
	alignRightIcon,
	defaultStylesIcon,
	folderIcon,
	hoveredStylesIcon,
} from './icons.js';
import BuilderColorPicker from './form-items/builder-color-picker.js';
import TextArea from 'emcomp/library/components/text-area/text-area.js';
import Form, { FormElement } from 'emcomp/library/components/form/form.js';
import { translations } from './translations.js';
import { BuilderElementElement } from './builder-element.js';
import Slider from 'emcomp/library/components/slider/slider.js';

interface ElementsCssEditorProps extends WompoProps {
	fonts: any[];
	i18n: typeof translations.en;
	iframe: RefHook<HTMLIFrameElement>;
	editingItem: BuilderElementElement;
	imageSelector: (currentValue: string) => Promise<{ value: string; name: string }>;
	palette: [string, string, string, string, string, string, string, string, string, string, string];
}

interface ValueType {
	style?: Partial<CSSStyleDeclaration>;
	styleHovered?: Partial<CSSStyleDeclaration>;
	class?: string;
	id?: string;
	raw?: string;
}

export default function ElementsCssEditor({
	i18n,
	iframe,
	imageSelector,
	palette,
	fonts,
	styles: s,
}: ElementsCssEditorProps) {
	const valueRef = useRef<ValueType>(null);
	const [type, setType] = useState<'style' | 'styleHovered'>('style');

	const formRef = useRef<FormElement>();

	useEffect(() => {
		formRef.current.reset();
		formRef.current.setData(valueRef.current);
	}, [type]);

	const onChangeStyle = (ev: InputEvent) => {
		ev.stopPropagation();
		const target = ev.currentTarget as ButtonGroupElement;
		setType(target.value);
	};

	const onInput = (ev: InputEvent) => {
		ev.stopPropagation();
		const form = ev.currentTarget as FormElement;
		const data = form.getData();
		valueRef.current = {
			...valueRef.current,
			...data,
		};
		this.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
		this.dispatchEvent(new Event('change', { bubbles: true, composed: true }));
	};

	useExposed({
		_$emForm: true,
		value: () => valueRef.current,
		setValue: (newValue: ValueType) => {
			if (!newValue) {
				valueRef.current = null;
				formRef.current.empty();
			} else {
				setType('style');
				valueRef.current = newValue;
				formRef.current.setData(newValue);
			}
		},
		name: 'css',
		disabled: false,
		readonly: false,
		validate: () => formRef.current.validate(),
		setTab: setType,
	});

	return html`
    <${ButtonGroup} value=${type} @input=${onChangeStyle} style="margin: 20px 0;">
      <${Button} value="style">${defaultStylesIcon}</${Button}>
      <${Button} value="styleHovered">${hoveredStylesIcon}</${Button}>
    </${ButtonGroup}>

    <${Form} ref=${formRef} @input=${onInput}>
      <${Accordion}>
        <${AccordionItem} title=${i18n.containerOptions}>
          <${MeasureInput} label=${i18n.width} name="${type}.width" />
          <${MeasureInput} label=${i18n.height} name="${type}.height" />
          <${BoxInput} label=${i18n.padding} name="${type}.padding" />
          <${BoxInput} label=${i18n.margin} name="${type}.margin" canChangeUnit=${true} />
          <${CornerBoxInput} label=${i18n.borderRadius} name="${type}.borderRadius" />
          <${BoxInput} label=${i18n.borderWidth} name="${type}.borderWidth" />
          <${Select}
            label=${i18n.borderStyle}
            name="${type}.borderStyle"
            appendMenuTo=${document.body}
          >
            <${MenuItem} value="solid">Solid</${MenuItem}>
            <${MenuItem} value="dashed">Dashed</${MenuItem}>
            <${MenuItem} value="dotted">Dotted</${MenuItem}>
            <${MenuItem} value="double">Double</${MenuItem}>
            <${MenuItem} value="groove">Groove</${MenuItem}>
            <${MenuItem} value="inset">Inset</${MenuItem}>
            <${MenuItem} value="outset">Outset</${MenuItem}>
          </${Select}>
          <${TextInput}
            label=${i18n.backgroundImage}
            name="${type}.backgroundImage"
            trailingIcon=${imageSelector && folderIcon}
            trailingIconClickCallback=${imageSelector}
          />
          <${Select}
            label=${i18n.backgroundSize}
            name="${type}.backgroundSize"
            appendMenuTo=${document.body}
          >
            <${MenuItem} value="auto">Auto</${MenuItem}>
            <${MenuItem} value="contain">Contain</${MenuItem}>
            <${MenuItem} value="cover">Cover</${MenuItem}>
          </${Select}>
          <${Select}
            label=${i18n.backgroundPosition}
            name="${type}.backgroundPosition"
            appendMenuTo=${document.body}
          >
            <${MenuItem} value="bottom">Bottom</${MenuItem}>
            <${MenuItem} value="center">Center</${MenuItem}>
            <${MenuItem} value="left">Left</${MenuItem}>
            <${MenuItem} value="right">Right</${MenuItem}>
            <${MenuItem} value="top">Top</${MenuItem}>
          </${Select}>
        </${AccordionItem}>
        <${AccordionItem} title=${i18n.textOptions}>
          <${Select} label=${i18n.font} name="${type}.fontFamily" appendMenuTo=${document.body}>
            <${MenuItem} value=""><i>None</i></${MenuItem}>
            ${fonts}
          </${Select}>
          <${Slider}
            label=${i18n.fontSize}
            name="${type}.fontSize"
            min="12"
            max="100"
            centered
          />
          <${ButtonGroup} name="${type}.textAlign" label=${i18n.textAlign}>
            <${Button} style="width: 100%;" value="left">${alignLeftIcon}</${Button}>
            <${Button} style="width: 100%;" value="center">${alignCenterIcon}</${Button}>
            <${Button} style="width: 100%;" value="right">${alignRightIcon}</${Button}>
          </${ButtonGroup}>
        </${AccordionItem}>
        <${AccordionItem} title=${i18n.colors}>
          <${BuilderColorPicker}
            iframe=${iframe}
            palette=${palette}
            label=${i18n.backgroundColor}
            name="${type}.backgroundColor"
            withOpacity
          />
          <${BuilderColorPicker}
            iframe=${iframe}
            palette=${palette}
            label=${i18n.textColor}
            name="${type}.color"
          />
          <${BuilderColorPicker}
            iframe=${iframe}
            palette=${palette}
            label=${i18n.borderColor}
            name="${type}.borderColor"
          />
        </${AccordionItem}>
        <${AccordionItem} title=${i18n.advanced}>
          <${TextInput} autocomplete="off" label=${i18n.classes} name="class" />
          <${TextInput} autocomplete="off" label=${i18n.id} name="id" />
          <${TextArea}
            autocomplete="off"
            label=${i18n.css}
            name="raw"
            class=${s.cssEditor}
            supportingText=${i18n.cssHelp}
          />
        </${AccordionItem}>
      </${Accordion}>
    </${Form}>
  `;
}

defineWompo(ElementsCssEditor, { name: 'em-builder-elements-css-editor' });
