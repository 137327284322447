import { defineWompo, html, useContext, useState, WompoProps } from 'wompo';
import { BuilderContext, WidgetItem } from './builder.js';
import { CanvasContext } from './canvas.js';
import { plusIcon } from './icons.js';

interface DropperProps extends WompoProps {
	position: string;
	droppable?: boolean;
}

export default function Dropper({ position, droppable, styles: s }: DropperProps) {
	const [hovered, setHovered] = useState(false);
	const builderCtx = useContext(BuilderContext);

	const { insert, move } = useContext(CanvasContext);

	const onDrop = () => {
		setHovered(false);
		const isWidget = !!builderCtx.draggingWidget.current;
		const widget = isWidget
			? builderCtx.draggingWidget.current
			: builderCtx.draggingElement.current.props.widget;
		const isGlobal = typeof widget.renderer !== 'function';
		if (isWidget)
			insert(
				position,
				widget,
				isGlobal
					? { ...(widget.renderer as WidgetItem), id: widget.id }
					: { id: widget.id, props: widget.defaultValues }
			);
		else
			move(builderCtx.draggingElement.current.props.position, position, widget, {
				id: widget.id,
				props: builderCtx.draggingElement.current.props.widgetProps,
			});
	};

	const allowDrop = (event: DragEvent) => {
		event.preventDefault();
		setHovered(true);
	};

	/**
	 * Handle the dragleave event
	 */
	const onDragLeave = () => {
		setHovered(false);
	};

	return html`<div
		@dragover=${allowDrop}
		@dragleave=${onDragLeave}
		@drop=${onDrop}
		@click=${() => builderCtx.openCompSheet()}
		class="${s.dropper} ${(hovered || droppable) && s.hovered}"
	>
		${plusIcon}
	</div>`;
}

Dropper.css = `
  :host {
    display: block;
    padding: 24px;
		transition: max-height .2s linear, padding-top .2s linear, padding-bottom .2s linear;
  }
  .dropper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    padding: 10px;
    border-radius: 16px;
    border: 1px dashed var(--color-dark-light);
    color: var(--color-dark-light);
    margin-top: 10px;
  }
  .dropper.hovered, .dropper:hover {
    color: var(--color-on-dark-light);
    background-color: var(--color-dark-light);
    border-color: var(--color-on-dark-light);
		cursor: pointer;
  }
`;

defineWompo(Dropper, { name: 'em-builder-dropper' });
